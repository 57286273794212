export const environment = {
  production: true,
  url: 'https://api.sandbox-taqe.com/v2',
  branch_key: 'key_live_gdwaWEQt6nVzI7hXGFPy5amhuuk6R2tV',
  link_branch: 'https://taqe.app.link/',
  url_pwa: 'https://app.sandbox-taqe.com',
  url_app_v4: 'https://app-v4.sandbox-taqe.com',
  type: 'taqe',
  default_SEO_img: 'https://cdn.branch.io/branch-assets/1535740907442-og_image.png',
  url_landing: 'https://vagas.sandbox-taqe.com',
  whiteLabel: {
    espro: {
      url_pwa: 'https://espro-app.sandbox-taqe.com',
      url_app_v4: 'https://espro-app-v4.sandbox-taqe.com',
    },
    'ciee-rj': {
      url_pwa: 'https://cieerj-app.sandbox-taqe.com',
      url_app_v4: 'https://espro-app-v4.sandbox-taqe.com',
    }
  }
};
